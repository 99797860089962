import React from 'react';
import Menu from './Menu';
import './Header.css';

const Header = () => {
  return (
    <div>
      <header className="text-end py-5 rounded">
        <div className="container">
          <h1 className="display-5 fw-bold">Les Bruyères de Servière</h1>
          <p className="lead fw-bold">Golden Retriever</p>
        </div>
      </header>
      <Menu />
    </div>
    
  );
}

export default Header;