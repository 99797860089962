import React from 'react';
import { Navbar, Nav, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Menu.css';

import fb from './img/icons8-facebook-48.png'
import tk from './img/icons8-tiktok-48.png'

const Menu = () => {
  return (
      <Navbar expand="lg" className="bg-body-tertiary shadow-sm p-2 mb-2 rounded" bg="primary" data-bs-theme="dark">
        <div className="container">
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav fill className="justify-content-center">
              <Nav.Link as={Link} to="/" className="link">News</Nav.Link>
              {/*<Nav.Link as={Link} to="/nos-chiens" className="link">Nos chiens</Nav.Link>*/}
              <Nav.Link as={Link} to="/devenir-famille" className="link">Devenez famille d'élevage</Nav.Link>
              <Nav.Link as={Link} to="/carnet-de-naissances" className="link">Carnet de naissances</Nav.Link>
              <Nav.Link as={Link} to="/contact" className="link">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Nav fill className='justify-content-right'>
            <Nav.Link as={Link} to="https://www.facebook.com/people/%C3%89levage-des-Bruy%C3%A8res-de-Servi%C3%A8re/100057480758331/" className="logo">
              <Image src={fb} roundedCircle/>
            </Nav.Link>
            {/*<Nav.Link as={Link} to="" className="logo">
              <Image src={tk} roundedCircle/>
            </Nav.Link>*/}
          </Nav>
        </div>
      </Navbar>
  );
}

export default Menu;
