import React from 'react';
import { Link } from 'react-router-dom';

import CarouselGolden from './CarouselGolden';

const images = require.context('./img/golden/naissance', true);
const imageList = images.keys().map(image => images(image));

const Births = () => {
  return (
    <div className="container">
      <h1 className='mb-3'>Carnet de naissances</h1>
      <p>Des chiots seront bientôt disponibles ! N'hésitez pas à <Link to='/Contact'>nous contacter</Link> pour plus d'informations.</p>
      <CarouselGolden imageList={imageList}/>
    </div>
  );
};

export default Births;
